"use client";

import { useRouter } from "next/navigation";
import React, { useLayoutEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

/* Components */
import { Particles } from "@layouts/particles";
import { Button, Heading, Text } from "@components";

/* Animations */
import * as animationData from "@src/assets/animations/error.json";

/* Types */
import { ErrorJSON } from "@utils/error/types";

type Props = {
  children?: React.ReactNode;
  error: ErrorJSON;
  reset?: () => void;
};

export const ErrorLayout: React.FC<Props> = (props) => {
  const { error, reset } = props;

  /* Hooks */
  const router = useRouter();

  if (!props.reset) props.reset = () => window.location.reload();

  useLayoutEffect(() => {
    // document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <div className="overflow-hidden mt-4">
        <Particles />
        <div className="container grid grid-rows-2">
          <div className="flex flex-col gap-4">
            <div className="ml-12">
              <Player autoplay loop src={animationData} className="h-80" />
            </div>
            <div className="flex flex-col items-center gap-2">
              <Heading>Something went wrong</Heading>
              <Text>{error.message}</Text>
              <div className="flex mt-8 gap-12">
                <Button onClick={reset}>Try Again?</Button>
                <Button
                  variant="outline"
                  color="gradient"
                  onClick={() => router.push("/")}
                >
                  Go back home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
